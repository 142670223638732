import React from "react";
import { isBrowser } from "@emotion/core/src/utils";
import Project from "../templates/Project";

const unpublishedProject = () => {
    if(!isBrowser) {
        return null;
    }
    const previewData = window.__PRISMIC_PREVIEW_DATA__

    return <Project data={previewData} />
}

export default unpublishedProject;