import React from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import Button from "../Elements/Button";
import Container from "../Context/Container";
import Section from "../Context/Section";
import { Link } from "gatsby";
import color from "../../styles/colors";

const ReturnLink = styled(Link)`
    ${typography.link};
    margin-bottom: 16px;
    color: ${color("dark")};
    transition: color 0.2s ease;

    &:hover{
        color: ${color("dark blue")};
    }
`;

const ButtonExplore = styled(Button)`
    margin-top: 0;
    margin-bottom: 23px;

    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-bottom: 24px;
    }

    @media screen and (min-width: 1200px) {
        margin-top: 0;
    }

    @media screen and (min-width: 1800px) {
        margin-top: 0;
    }
`;

const ReturnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Return = ({returnText, returnUrl, buttonText, buttonUrl, buttonInternal, theme}) => {
    return (
        <Section bg="white" fg="dark" center={true} theme={theme}>
            <Container>
                <ReturnWrapper>
                    <ButtonExplore text={buttonText} url={buttonUrl} internal={buttonInternal} />
                    <div>
                        <ReturnLink to={returnUrl}>
                            {returnText}
                        </ReturnLink>
                    </div>
                </ReturnWrapper>
            </Container>
        </Section>
    );
};

export default Return;
