import React from "react";

import GatsbyImage from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import HeroArticle from "../components/General/HeroArticle";
import Body from "../components/General/Body";
import Footer from "../components/Context/Footer";
import FeatureImage from "../components/General/FeatureImage";
import Return from "../components/Projects/Return";
import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";

const Project = ({ data: staticData }) => {
    const data = usePreviewData(staticData);
    const {
        name,
        tagline,
        project_link,
        feature_image,
        content,
        meta_description,
        share_image
    } = data.prismicProject.data;
    const theme = "dark";
    return (
        <Layout>
            <SEO title={name.text} description={meta_description} image={share_image.url} />
            <Header theme={theme} />
            <HeroArticle theme={theme} title={name.text} tagline={tagline} exploreUrl={project_link.url} exploreText="Explore project" />
            <FeatureImage theme={theme} image={feature_image.fluid ? <GatsbyImage fluid={feature_image.fluid} alt={feature_image.alt} loading={"eager"} /> : feature_image.fluid} credits={feature_image.copyright} />
            <Body theme={theme} type="project" content={content.html} title={name.text} pageUrl={data.prismicProject.url} />
            <Return theme={theme} returnText="Return to projects" returnUrl="/projects" buttonUrl={project_link.url} buttonText="Explore project" buttonInternal={false} />
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    query Project($slug: String!) {
        prismicProject(uid: {eq: $slug}) {
            uid
            url
            data {
                feature_image {
                    alt
                    copyright
                    fluid(maxWidth: 1800) {
                        ...GatsbyPrismicImageFluid
                    }
                    thumbnails {
                        Mobile {
                            fluid {
                                ...GatsbyPrismicImageFluid
                            }
                        }
                    }
                }
                name {
                    text
                }
                project_link {
                    url
                    target
                    link_type
                }
                content {
                    html
                }
                tagline
                type
                meta_description
                share_image {
                    url
                }
            }
        }
    }
`



export default Project;
